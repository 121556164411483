h2 {
    font-size: 18px;
    font-weight: bold;
    font-family: 'Courier New', Courier, monospace;
    margin: 10px;
    margin-bottom: 5px;
    margin-left: 15px;
}

h3 {
    font-size: 13px;
    font-weight: normal;
    font-family: 'Courier New', Courier, monospace;
    margin-top: 0px;
    margin-left: 15px;
}